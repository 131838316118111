@use "../abstract/mixins" as mixin;
@use "../abstract/responsive" as rwd;

$c-concrete: #F3F3F3; 
$c-pink-salmon: #FF95AB;
$c-white: #FFFFFF;   

$transition: all .4s ease;  

.longVersion {
  .icon {
    background-color: var(--secondColorCityQuide);
  }

  @include rwd.breakpoint(smallPhones) {
    .cityGuide__list {
      li {
        height: 100px;
        overflow: hidden;
      }

      .lnkBox {
        height: 100px;
      }
    }
  }
}

.displayEventAmount {
  .cityGuide__item {
    .boxData {
      display: flex;
      flex-direction: column;
    }

    .eventAmount {
      @include mixin.font(14px, 16px, 400, $c-white);
      padding: 2px 8px;
      margin: 4px 0 0;
      width: max-content;
      font-variant-numeric: tabular-nums;
    }
  }
}

// City guide section
.cityGuide__list {
  @include rwd.breakpoint(smallPhonesUp) {
    display: grid;
    grid-template-columns: 1.4fr 0.6fr 0.5fr 1.5fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 20px 20px;
    grid-template-areas:
      "news-1 news-1 news-2 news-2"
      "news-3 news-4 news-4 news-4"
      "news-5 news-5 news-5 news-6"
      "news-7 news-7 news-8 news-8";
  }

  @include rwd.breakpoint(mobile) {
    /* padding-bottom: 10px; */
  }

  @include rwd.breakpoint(small) {
    gap: 10px 10px;
  }

  @media screen and (min-width: 480px) and (max-width: 575px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 15px 15px;
    grid-template-areas:
      "news-1 news-2"
      "news-3 news-4"
      "news-5 news-6"
      "news-7 news-8";
  }

  .news-1 {
    grid-area: news-1;

    .eventAmount,
    .boxTitle {
      background-color: var(--firstColorCityQuide);
    }
  }

  .news-2 {
    grid-area: news-2;

    .eventAmount,
    .boxTitle {
      background-color: var(--secondColorCityQuide);
    }
  }

  .news-3 {
    grid-area: news-3;

    .eventAmount,
    .boxTitle {
      background-color: var(--firstColorCityQuide);
    }
  }

  .news-4 {
    grid-area: news-4;

    .eventAmount,
    .boxTitle {
      background-color: var(--thirdColorCityQuide);
    }
  }

  .news-5 {
    grid-area: news-5;

    .eventAmount,
    .boxTitle {
      background-color: var(--secondColorCityQuide);
    }
  }

  .news-6 {
    grid-area: news-6;

    .eventAmount,
    .boxTitle {
      background-color: var(--thirdColorCityQuide);
    }
  }

  .news-7 {
    grid-area: news-7;

    .eventAmount,
    .boxTitle {
      background-color: var(--thirdColorCityQuide);
    }
  }

  .news-8 {
    grid-area: news-8;

    .eventAmount,
    .boxTitle {
      background-color: var(--firstColorCityQuide);
    }
  }

  li {
    position: relative;
    height: 100%;

    @include rwd.breakpoint(smallPhones) {
      margin-bottom: 10px;
      height: auto;
    }
    
    &:last-child {
      @include rwd.breakpoint(tabletmobile) {
        margin-bottom: 0;
      }
    }
  }

  .lnkBox {
    height: 169px;
    display: block;
    overflow: hidden;
    @include mixin.border($c-concrete, 1px);

    @include rwd.breakpoint(small) {
      height: 159px;
    }

    .withBgCover {
      @include mixin.bgCover();
      transform: scale(1);
      height: 100%;
      overflow: hidden;
      transition: $transition;
      width: 100%;

      &:after {
        @include mixin.position(absolute, false, false, 0, 0);
        content: "";
        width: 100%;
        height: 55%;
        background: linear-gradient(
          to bottom,
          transparent 0%,
          rgba(0, 0, 0, 0.7) 60%
        );
      }
    }

    .boxData {
      @include mixin.position(absolute, false, 25px, 25px, 25px);
      display: inline-flex;

      .textLabel {
        color: $c-white;
      }

      @include rwd.breakpoint(desktopsDown) {
        left: 15px;
        right: 15px;
        bottom: 15px;
      }

      .boxTitle {
        @include mixin.font(18px, 24px, false, $c-white);
        @include mixin.showLines(1);
        transition: $transition;
        padding: 2px 8px;
        width: fit-content;

        @include rwd.breakpoint(mobile) {
          margin-top: 3px;
        }
      }
    }

    &:hover,
    &:focus {
      @include rwd.breakpoint(smallUp) {
        .withBgCover {
          transform: scale(1.1);
        }

        .boxTitle {
          text-decoration: underline;
        }
      }
    }
  }

  .noPhoto {
    .withBgCover {
      display: none;
    }

    .lnkBox {
      background-color: var(--main-color);

      @include rwd.breakpoint(smallPhones) {
        height: 100px;
      }

      .textLabel {
        color: $c-pink-salmon;
      }

      .boxData {
        right: 80px;
      }

      .boxTitle {
        @include mixin.showLines(2);

        @include rwd.breakpoint(smallPhones) {
          @include mixin.font(20px, 26px);
        }

        @media screen and (min-width: 480px) and (max-width: 575px) {
          max-width: 170px;
        }

        @include rwd.breakpoint(phonesDown) {
          @include mixin.font(20px, 24px);
        }
      }

      .icon {
        @include mixin.position(absolute, false, 30px, 30px);
        color: $c-white;
        font-size: 24px;
        transition: $transition;
        width: 28px;
        height: 38px;
        @include mixin.flexCenter();

        @include rwd.breakpoint(smallPhones) {
          bottom: 10px;
          transform: translateY(-50%);
        }
      }
    }

    &.largeTxt {
      .boxTitle {
        @include rwd.breakpoint(mobileUp) {
          @include mixin.font(26px, 33px);
        }
      }
    }

    &:hover,
    &:focus {
      @include rwd.breakpoint(smallUp) {
        .icon {
          right: 20px;
        }
      }
    }
  }
}
